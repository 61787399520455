exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dotaznik-tsx": () => import("./../../../src/pages/dotaznik.tsx" /* webpackChunkName: "component---src-pages-dotaznik-tsx" */),
  "component---src-pages-fotogalerie-tsx": () => import("./../../../src/pages/fotogalerie.tsx" /* webpackChunkName: "component---src-pages-fotogalerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-projektu-tsx": () => import("./../../../src/pages/o-projektu.tsx" /* webpackChunkName: "component---src-pages-o-projektu-tsx" */),
  "component---src-pages-odkazy-tsx": () => import("./../../../src/pages/odkazy.tsx" /* webpackChunkName: "component---src-pages-odkazy-tsx" */),
  "component---src-pages-okruzni-krizovatky-tsx": () => import("./../../../src/pages/okruzni-krizovatky.tsx" /* webpackChunkName: "component---src-pages-okruzni-krizovatky-tsx" */),
  "component---src-pages-seminar-tsx": () => import("./../../../src/pages/seminar.tsx" /* webpackChunkName: "component---src-pages-seminar-tsx" */),
  "component---src-pages-viceucelove-pruhy-tsx": () => import("./../../../src/pages/viceucelove-pruhy.tsx" /* webpackChunkName: "component---src-pages-viceucelove-pruhy-tsx" */)
}

